export type LocalizedErrorMessages = {
  unauthorized: string;
  orderNotFound: string;
  internalServerError: string;
  chatMessageTooLong: string;
  sendMessageNotAvailable: string;
};

const defaultLocalizedErrorMessages: LocalizedErrorMessages = {
  unauthorized: "We're sorry. We are not able to authenticate you.",
  orderNotFound: "We're sorry, we can't find that order.",
  internalServerError: "We seem to be experiencing technical difficulties. Please try again later.",
  chatMessageTooLong:
    "We're sorry, that message is too long. Please keep messages under 1000 characters.",
  sendMessageNotAvailable: "We're sorry, we can't send messages to the shopper at this time.",
};

export interface IAPIError {
  status: number;
  statusText: string;
  connectCode?: number;
  connectMessage?: string;
  getErrorMessage: () => string;
}

export class APIError extends Error implements IAPIError {
  static localizedMessages: LocalizedErrorMessages = defaultLocalizedErrorMessages;

  public static setLocalizedErrorMessages(newLocalizedMessages?: LocalizedErrorMessages) {
    if (!newLocalizedMessages) return;

    Object.keys(newLocalizedMessages).forEach((key) => {
      const defaultMessage = defaultLocalizedErrorMessages[key as keyof LocalizedErrorMessages];
      const newMessage = newLocalizedMessages[key as keyof LocalizedErrorMessages];

      this.localizedMessages[key as keyof LocalizedErrorMessages] = newMessage
        ? newMessage
        : defaultMessage;
    });
  }

  constructor(
    public status: number, // from response.status
    public statusText: string, // from response.statusText
    public connectCode?: number, // Connect API specific error code
    public connectMessage?: string // Connect API specific error message
  ) {
    super();
    this.message = this.getErrorMessage();
  }

  public getErrorMessage(): string {
    switch (this.status) {
      case 401:
        return APIError.localizedMessages.unauthorized;
      case 404:
        switch (this.connectCode) {
          case 4000:
          default:
            return APIError.localizedMessages.orderNotFound;
        }
      case 500:
        return APIError.localizedMessages.internalServerError;
    }
    return this.statusText;
  }
}

export class SendChatMessageAPIError extends APIError implements IAPIError {
  public getErrorMessage(): string {
    switch (this.status) {
      case 400:
        switch (this.connectCode) {
          case 1001:
            return APIError.localizedMessages.chatMessageTooLong;
        }
        break;
      case 403:
        return APIError.localizedMessages.sendMessageNotAvailable;
    }
    return super.getErrorMessage();
  }
}
