import { useContext, useEffect, useState } from "preact/hooks";
import ApiClient, { HeaderMetadataResult } from "../api";
import { ConfigurationForOrderStatus } from "../types/theme";
import { useQuery } from "react-query";
import { Api } from "./contexts";
import { APIError } from "../OSPError";

export const useApi = (): ApiClient => {
  return useContext(Api);
};

export const useConfigurationForOrderStatus = (orderId?: string) => {
  const api = useApi();

  return useQuery<ConfigurationForOrderStatus, APIError>(
    ["configurationForOrderStatus", orderId],
    () => api.getConfigurationForOrderStatus("order_status", orderId)
  );
};

export const useHeaderMetadata = (
  data: ConfigurationForOrderStatus | undefined,
  loading: boolean
): HeaderMetadataResult => {
  const [headerMetadataResult, setHeaderMetadataResult] = useState<HeaderMetadataResult>({
    loading: true,
  });

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data?.branding?.logoUrl) {
      const image = new Image();
      image.onload = function () {
        setHeaderMetadataResult({
          data: { logoAspectRatio: image.width / image.height },
          loading: false,
        });
      };
      image.onerror = function () {
        setHeaderMetadataResult({
          loading: false,
        });
      };

      image.src = data.branding.logoUrl;
    } else {
      setHeaderMetadataResult({
        loading: false,
      });
    }
  }, [data, loading]);

  return headerMetadataResult;
};
