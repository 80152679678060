export interface ClientEnv {
  apiUrl: string;
  mapboxKey: string;
  sentryDsn: string;
  sentryEnv: string;
  mongooseLoggingEnabled: boolean;
}

const apiUrl: string = (document.head.querySelector("meta[name='api-url']") as HTMLMetaElement)
  ?.content;
if (!apiUrl) {
  throw new Error("No apiUrl meta header found");
}
const mapboxKey = (document.head.querySelector("meta[name='mapbox-key']") as HTMLMetaElement)
  ?.content;

const sentryDsn = (document.head.querySelector("meta[name='sentry-dsn']") as HTMLMetaElement)
  ?.content;

const sentryEnv = (
  document.head.querySelector("meta[name='sentry-environment']") as HTMLMetaElement
)?.content;
if (!sentryEnv) {
  throw new Error("No mapbox key found in head");
}

const mongooseLoggingEnabled =
  (document.head.querySelector("meta[name='mongoose-logging-enabled']") as HTMLMetaElement)
    ?.content === "true";

const clientEnv: ClientEnv = {
  apiUrl,
  mapboxKey,
  sentryDsn,
  sentryEnv,
  mongooseLoggingEnabled,
};

export default clientEnv;
