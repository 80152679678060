import { FunctionalComponent, h } from "preact";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import style from "./style.css";
import { HeaderMetadata } from "../api";
import BrandLogo from "../brand_logo";
import { ChevronLeftIcon } from "@instacart/ids-core";

const Header: FunctionalComponent<{ metadata?: HeaderMetadata }> = ({ metadata }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const renderBackButton = () => {
    return !matchPath("/orders/:orderId", pathname);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <header className={style.header} data-testid="header">
      {renderBackButton() && (
        // eslint-disable-next-line @typescript-eslint/unbound-method
        <button className={style.back_button} onClick={handleBackClick}>
          <ChevronLeftIcon size={24} />
        </button>
      )}
      <BrandLogo metadata={metadata} />
      {renderBackButton() && <div className={style.spacer} />}
    </header>
  );
};

export default Header;
