import { ComponentChildren, Fragment, h } from "preact";
import { useErrorBoundary } from "preact/hooks";
import ErrorModal from "./error_modal";
import * as sentry from "@sentry/browser";

interface ErrorBoundaryProps {
  children?: ComponentChildren;
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [error] = useErrorBoundary((error: Error) => {
    sentry.captureException(error);
  });

  if (error) return <ErrorModal />;

  return <Fragment>{children}</Fragment>;
}

export default ErrorBoundary;
