import "./style/index.css";
import App from "./components/app";
import * as sentry from "@sentry/browser";
import clientEnv from "./utils/clientEnv";

sentry.init({
  dsn: clientEnv.sentryDsn,
  environment: clientEnv.sentryEnv,
});

export default App;
