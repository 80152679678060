import { h } from "preact";
import style from "./style.css";
import LocalizedIDSText from "../text/LocalizedIDSText";

interface ErrorModalProps {
  titleLocaleKey?: string;
  descriptionLocaleKey?: string;
}

function ErrorModal({
  titleLocaleKey = "error_state.error_modal.title",
  descriptionLocaleKey = "error_state.error_modal.description",
}: ErrorModalProps) {
  return (
    <div className={style.error_modal_container}>
      <div className={style.error_box}>
        <img
          className={style.error_image}
          src={"/assets/images/error_image.svg"}
          alt={"error image"}
          data-testid="error_image"
        />

        <LocalizedIDSText
          as="h2"
          typography="subtitleLarge"
          color="systemGrayscale50"
          className={style.error_header}
          localeId={titleLocaleKey}
          data-testid="error_state_title"
        />

        <LocalizedIDSText
          as="span"
          typography="bodyMedium2"
          color="systemGrayscale50"
          className={style.error_subtext}
          localeId={descriptionLocaleKey}
          data-testid="error_state_subtext"
        />
      </div>
    </div>
  );
}

export default ErrorModal;
