import { FunctionalComponent, h } from "preact";
import { useContext } from "preact/hooks";
import { HeaderMetadata } from "../api";
import BrandLogo from "../brand_logo";
import { Theme } from "../core/theme_context";
import style from "./style.css";
import LocalizedIDSText from "../text/LocalizedIDSText";

const Footer: FunctionalComponent<{ metadata?: HeaderMetadata }> = ({ metadata }) => {
  const { branding } = useContext(Theme);
  const { faqUrl, customerSupportUrl, retailerUrl, name } = branding;

  if (!faqUrl && !customerSupportUrl) {
    return null;
  }

  return (
    <div className={style.footer} data-testid="footer">
      <div className={style.footer_content}>
        <div className={style.footer_logo}>
          <BrandLogo metadata={metadata} />
        </div>
        <div className={style.store_info}>
          <div className={style.footer_name}>{name}</div>
          <a href={retailerUrl} className={style.footer_url}>
            {retailerUrl}
          </a>
        </div>

        <div className={style.footer_links} data-testid="footer-links">
          {faqUrl && (
            <a className={style.footer_link} data-testid="footer-link" href={faqUrl}>
              <LocalizedIDSText
                as="span"
                typography="bodyMedium2"
                className={style.footer_text}
                localeId="footer.link.faq"
              />
            </a>
          )}
          {customerSupportUrl && (
            <a className={style.footer_link} data-testid="footer-link" href={customerSupportUrl}>
              <LocalizedIDSText
                as="span"
                typography="bodyMedium2"
                className={style.footer_text}
                localeId="footer.link.customer_support"
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
