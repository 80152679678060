export type ConfigurationForOrderStatus = Readonly<{
  branding: Branding;
  styles: Styles;
  copy: Copy;
  toggledWidgets: ToggledWidgets;
}>;

export type Branding = Readonly<{
  name: string;
  logoUrl: string;
  retailerUrl: string;
  editOrderUrl?: string;
  faqUrl?: string;
  customerSupportUrl?: string;
}>;

export type Styles = Readonly<{
  primaryBackground: string;
  primaryForeground: string;
  secondaryBackground: string;
  secondaryForeground: string;
  fontFamily?: FontFamily;
  buttonRadius: number;
}>;

export type FontFamily = Array<{
  name: string;
  fontVariants: Array<{
    weight: number;
    weights: Array<{
      name: string;
      url: string;
    }>;
  }>;
}>;

export type Copy = Readonly<{
  tipPrompt: string;
  thankYou: string;
}>;

export type ToggledWidgets = Widget[];

export enum Widget {
  delivery_status = "delivery_status",
  delivery_map = "delivery_map",
  delivery_details = "delivery_details",
  item_list = "item_list",
  chat = "chat",
  pickup = "pickup",
}
