import { useApi } from "../core/hooks";
import { Order } from "../types/orders";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PickupRecordUserArrival } from "../types/pickup";
import { APIError } from "../OSPError";

export const useOrder = (orderId?: string) => {
  const api = useApi();
  return useQuery<Order, APIError>(["order", orderId], () => api.getOrder(orderId), {
    refetchInterval: 30000,
  });
};

export const useRecordUserArrival = (
  orderId: string,
  options?: { onSuccessCallback?: () => void; onErrorCallback?: () => void }
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { onSuccessCallback, onErrorCallback } = options || {};

  return useMutation<PickupRecordUserArrival, APIError>(
    ["recordUserArrival", orderId],
    () => api.recordUserArrival(orderId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ["order", orderId] });
        onSuccessCallback?.();
      },
      onError: onErrorCallback,
    }
  );
};
