import { useEffect, useState } from "preact/hooks";
import { LocalizedErrorMessages, APIError } from "../OSPError";

// when a new locale .json file is added under src/locales/ folder, we need to add its filename here
enum SUPPORTED_LOCALE {
  EN = "en",
  FR_CA = "fr_CA",
  ES_US = "es_US",
}

// default locale
export const DEFAULT_LOCALE = SUPPORTED_LOCALE.EN;

// map of user locale to our supported locale
const SUPPORTED_LOCALES_MAP: Record<string, SUPPORTED_LOCALE> = {
  en: SUPPORTED_LOCALE.EN,
  fr: SUPPORTED_LOCALE.FR_CA,
  fr_CA: SUPPORTED_LOCALE.FR_CA,
  es: SUPPORTED_LOCALE.ES_US,
  es_US: SUPPORTED_LOCALE.ES_US,
};

function useI18nDefinition(userLocale?: string) {
  const [definition, setDefinition] = useState<Record<string, unknown>>({});

  useEffect(() => {
    async function loadDefinition() {
      // make all user preferred locales into an array
      const browserLocales = navigator.languages || [navigator.language];
      const baseUserLocales = userLocale ? [userLocale, ...browserLocales] : browserLocales;

      // find the matched filename for the user preferred locale
      let selectedLocale: SUPPORTED_LOCALE = DEFAULT_LOCALE;

      const userLocales: string[] = [];
      baseUserLocales.forEach((locale) => {
        userLocales.push(locale);

        const localeWithoutCountryCode = locale.trim().split(/-|_/)[0];
        if (locale !== localeWithoutCountryCode) userLocales.push(localeWithoutCountryCode);
      });

      const selectedLocaleKey = userLocales.find((locale) => !!SUPPORTED_LOCALES_MAP[locale]);
      if (selectedLocaleKey) selectedLocale = SUPPORTED_LOCALES_MAP[selectedLocaleKey];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const definitionModule = await import(`../../locales/${selectedLocale}.json`);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      setDefinition(definitionModule?.default);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadDefinition();
  }, [userLocale]);

  useEffect(() => {
    APIError.setLocalizedErrorMessages(definition?.error_messages as LocalizedErrorMessages);
  }, [definition]);

  return definition;
}

export default useI18nDefinition;
