import { ComponentChildren, createContext, h } from "preact";
import { useCallback, useContext, useEffect, useState } from "preact/hooks";

type WindowContextType = {
  isMobile: boolean;
  viewportWidth?: number;
};

const defaultWindowContext: WindowContextType = {
  isMobile: false,
  viewportWidth: undefined,
};

/**
 * WindowContext provides information related to the window,
 * such as the current viewport width and whether the current screen is considered mobile.
 */
const WindowContext = createContext<WindowContextType>(defaultWindowContext);

export function WindowProvider({ children }: { children: ComponentChildren }) {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const handleViewportResize = useCallback(() => {
    setViewportWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleViewportResize);
    return () => window.removeEventListener("resize", handleViewportResize);
  }, [handleViewportResize]);

  const isMobile = viewportWidth <= 600;

  return (
    <WindowContext.Provider value={{ viewportWidth, isMobile }}>{children}</WindowContext.Provider>
  );
}

export function useWindowContext() {
  return useContext(WindowContext);
}
