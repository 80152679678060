import { h } from "preact";
import { BrowserRouter, Route, Routes, matchPath } from "react-router-dom";
import NotFoundPage from "../routes/notfound";
import Header from "./header";
import style from "./style.css";
import OrderStatus from "../routes/order_status";
import ErrorModal from "./error_state/error_modal";
import Footer from "./footer";
import { ThemeProviders } from "./ThemeProviders";
import useI18nDefinition from "./hooks/useI18nDefinition";
import { IntlProvider } from "preact-i18n";
import ErrorBoundary from "./error_state/error_boundary";
import { useConfigurationForOrderStatus, useHeaderMetadata } from "./core/hooks";
import { useOrder } from "./delivery_status/hooks";
import { WindowProvider } from "./core/WindowContext";

export function Router() {
  const pathname = location.pathname;
  const match = matchPath("/orders/:orderId/*", pathname);

  const orderId = match?.params.orderId;

  const {
    data: configData,
    isLoading: isLoadingConfig,
    isError: isGetConfigError,
  } = useConfigurationForOrderStatus(orderId);
  const {
    data: orderData,
    isLoading: isLoadingOrder,
    isError: isGetOrderError,
  } = useOrder(orderId);

  const headerMetadataResult = useHeaderMetadata(configData, isLoadingConfig);
  const i18nDefinition = useI18nDefinition(orderData?.locale);

  if (isLoadingConfig || isLoadingOrder || headerMetadataResult.loading)
    return <div id="preact_root" />;

  const hasError = isGetConfigError || isGetOrderError;

  return (
    <IntlProvider definition={i18nDefinition}>
      <ThemeProviders configurationData={configData}>
        {/* When the config or the order fail to load */}
        {hasError && <ErrorModal />}

        {!hasError && (
          <BrowserRouter>
            <ErrorBoundary>
              <WindowProvider>
                <Header metadata={headerMetadataResult.data} />
                <main className={style.app_content}>
                  <Routes>
                    <Route path="orders/:orderId/*" element={<OrderStatus />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                  <Footer metadata={headerMetadataResult.data} />
                </main>
              </WindowProvider>
            </ErrorBoundary>
          </BrowserRouter>
        )}
      </ThemeProviders>
    </IntlProvider>
  );
}
