import { FunctionalComponent, h } from "preact";
import { useContext } from "preact/hooks";
import { Theme } from "../core/theme_context";
import style from "./style.css";
import { HeaderMetadata } from "../api";

const BrandLogo: FunctionalComponent<{ metadata?: HeaderMetadata }> = ({ metadata }) => {
  const { branding } = useContext(Theme);

  return (
    <a data-testid="header-logo" href={branding.retailerUrl} className={style.header_item}>
      {branding.logoUrl ? (
        <img
          className={isWideLogo(metadata) ? style.wide_brand_logo : style.standard_brand_logo}
          src={branding.logoUrl}
        />
      ) : (
        <h1 data-testid="header-name">{branding.name}</h1>
      )}
    </a>
  );
};

function isWideLogo(metadata?: HeaderMetadata): boolean {
  if (!metadata) return false;

  return metadata.logoAspectRatio >= 4;
}

export default BrandLogo;
