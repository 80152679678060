import { ComponentProps, h } from "preact";
import { Text as IdsText } from "@instacart/ids-core";
import { Text } from "preact-i18n";

interface LocalizedIDSTextProps extends ComponentProps<typeof IdsText> {
  localeId: string;
  fields?: Record<string, string | number | undefined>;
  plural?: number;
}

function LocalizedIDSText({ localeId, fields, plural, ...idsProps }: LocalizedIDSTextProps) {
  return (
    <IdsText {...idsProps}>
      <Text id={localeId} fields={fields} plural={plural} />
    </IdsText>
  );
}

export default LocalizedIDSText;
