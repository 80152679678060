import { FunctionalComponent, h } from "preact";
import { MapBoxKey } from "./core/contexts";
import clientEnv from "../utils/clientEnv";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { isDevEnv } from "../utils/environment";
import { Router } from "./Router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      retryOnMount: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const App: FunctionalComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MapBoxKey.Provider value={clientEnv.mapboxKey}>
        <Router />
      </MapBoxKey.Provider>
      {isDevEnv() && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export default App;
