import camelCase from "camelcase-keys";
import ClientEnv from "../utils/clientEnv";

import {
  Order,
  OrderItems,
  OrderHandling,
  OrderLocation,
  ItemReplacementStatus,
} from "./types/orders";
import { ChatMessages, MarkMessagesAsRead, SentChatMessage } from "./types/chat";
import { ConfigurationForOrderStatus } from "./types/theme";
import {
  PickupVehicleInfo,
  PickupRecordUserArrival,
  PickupInstructions,
  VehicleFormParams,
} from "./types/pickup";
import { CreateDeliveryConfirmationResponse } from "./types/certified_delivery";
import { devMockEnabled } from "../utils/environment";
import { defaultConfiguration } from "./core/theme_context";
import { APIError, IAPIError, SendChatMessageAPIError } from "./OSPError";

export type HeaderMetadataResult = Readonly<{
  data?: HeaderMetadata;
  loading: boolean;
}>;

export type HeaderMetadata = Readonly<{
  logoAspectRatio: number;
}>;

export type ErrorResponse = Readonly<{
  error?: {
    message: string;
    code: number;
  };
}>;

export type ApiResult<T> = Readonly<{
  data?: T;
  loading: boolean;
  error?: Error;
}>;

export default class ApiClient {
  accessToken: string;
  retailer: string;
  apiRoot: string;

  constructor() {
    const urlParams = new URLSearchParams(window.location.search);

    this.accessToken = urlParams.get("access_token") || "";
    this.retailer = urlParams.get("retailer") || "kroger";
    this.apiRoot = ClientEnv.apiUrl;
  }

  fetchHeaders = (): Headers => {
    return new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.accessToken}`,
      "X-Retailer-Id": `${this.retailer}`,
      "Cache-Control": "no-store",
    });
  };

  getOrder = (orderId?: string): Promise<Order> => {
    if (!orderId) return Promise.reject(new Error("No order available"));

    return this.get<Order>(`/v2/post_checkout/orders/${orderId}`);
  };

  getOrderItems = (orderId: string): Promise<OrderItems> => {
    return this.get<OrderItems>(`/v2/post_checkout/orders/${orderId}/items`);
  };

  getOrderHandling = (orderId: string): Promise<OrderHandling> =>
    this.get<OrderHandling>(`/v2/post_checkout/orders/${orderId}/handling`);

  getOrderLocation = (orderId: string): Promise<OrderLocation> =>
    this.get<OrderLocation>(`/v2/post_checkout/orders/${orderId}/location`);

  getConfigurationForOrderStatus = (
    pageType: string,
    orderId?: string
  ): Promise<ConfigurationForOrderStatus> => {
    if (!orderId) return Promise.reject(new Error("No order available"));

    if (devMockEnabled()) {
      return Promise.resolve(defaultConfiguration);
    }

    return this.get<ConfigurationForOrderStatus>(
      `/v2/post_checkout/configurations/${pageType}?order_id=${orderId}`
    );
  };

  getChatMessages = (orderId: string): Promise<ChatMessages> =>
    this.get<ChatMessages>(`/v2/post_checkout/chat/${orderId}/messages`);

  sendChatMessage = (orderId: string, chatMessage: string): Promise<SentChatMessage> => {
    return this.post<SentChatMessage>(
      `/v2/post_checkout/chat/${orderId}/messages`,
      { body: chatMessage },
      SendChatMessageAPIError
    );
  };

  setMessagesAsRead = (orderId: string): Promise<MarkMessagesAsRead> => {
    return this.put<MarkMessagesAsRead>(`/v2/post_checkout/chat/${orderId}/messages`);
  };

  setReplacementStatus = (
    orderId: string,
    itemId: string,
    data: { status: ItemReplacementStatus }
  ): Promise<ErrorResponse> =>
    this.put<ErrorResponse>(
      `/v2/post_checkout/orders/${orderId}/items/${itemId}/replacement`,
      data
    );

  getVehicleInfo = (orderId: string): Promise<PickupVehicleInfo> =>
    this.get<PickupVehicleInfo>(`/v2/post_checkout/orders/${orderId}/pickup/vehicle_info`);

  createUpdateVehicleInfo = (
    orderId: string,
    data: VehicleFormParams
  ): Promise<PickupVehicleInfo> => {
    return this.put<PickupVehicleInfo>(
      `/v2/post_checkout/orders/${orderId}/pickup/vehicle_info`,
      data
    );
  };

  getPickupInstructions = (orderId: string): Promise<PickupInstructions> =>
    this.get<PickupInstructions>(`/v2/post_checkout/orders/${orderId}/pickup/details`);

  recordUserArrival = (orderId: string): Promise<PickupRecordUserArrival> => {
    return this.post<PickupRecordUserArrival>(
      `/v2/post_checkout/orders/${orderId}/pickup/user_arrived`
    );
  };

  createDeliveryConfirmation = (
    orderId: string,
    data: { name: string }
  ): Promise<CreateDeliveryConfirmationResponse> => {
    return this.post<CreateDeliveryConfirmationResponse>(
      `/v2/post_checkout/orders/${orderId}/certified_delivery/confirm_order`,
      data
    );
  };

  private async get<T>(path: string): Promise<T> {
    const res = await fetch(`${this.apiRoot}${path}`, {
      method: "GET",
      headers: this.fetchHeaders(),
    });

    if (res.ok) {
      const data = (await res.json()) as Record<string, unknown>;
      return camelCase(data, { deep: true }) as T;
    }

    const { error } = (await res.json()) as ErrorResponse;
    throw new APIError(res.status, res.statusText, error?.code, error?.message);
  }

  private async put<T>(path: string, data = {}): Promise<T> {
    const res = await fetch(`${this.apiRoot}${path}`, {
      method: "PUT",
      headers: this.fetchHeaders(),
      body: JSON.stringify(data),
    });

    if (res.ok) {
      return res.json() as Promise<T>;
    }

    const { error } = (await res.json()) as ErrorResponse;
    throw new APIError(res.status, res.statusText, error?.code, error?.message);
  }

  private async post<T, E = IAPIError>(
    path: string,
    data = {},
    errorClass?: new (
      status: number,
      statusText: string,
      connectCode?: number,
      connectMessage?: string
    ) => E
  ): Promise<T> {
    const res = await fetch(`${this.apiRoot}${path}`, {
      method: "POST",
      headers: this.fetchHeaders(),
      body: JSON.stringify(data),
    });

    if (res.ok) {
      return res.json() as Promise<T>;
    }

    const { error } = (await res.json()) as ErrorResponse;
    const ErrorClass = errorClass || APIError;
    throw new ErrorClass(res.status, res.statusText, error?.code, error?.message);
  }
}
