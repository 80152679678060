import { ComponentChildren, h } from "preact";
import { useContext, useLayoutEffect, useMemo } from "preact/hooks";
import { Theme, getTheme } from "./core/theme_context";
import {
  FontLoader,
  IdsProvider,
  InstacartSansFontLoaderVariants,
  ThemeOverrides,
} from "@instacart/ids-core";
import { ErrorResponse } from "./api";
import { ConfigurationForOrderStatus } from "./types/theme";

interface ThemeProvidersProps {
  children: ComponentChildren;
  configurationData?: ConfigurationForOrderStatus | ErrorResponse;
}

export function ThemeProviders({ configurationData, children }: ThemeProvidersProps) {
  const { applyTheme } = useContext(Theme);
  const theme = getTheme(configurationData);
  useLayoutEffect(() => applyTheme(theme?.styles));

  const themeOverrides = useMemo<ThemeOverrides>(() => {
    const colors: ThemeOverrides["colors"] = {};
    if (theme.styles.primaryForeground) colors.brandPrimaryRegular = theme.styles.primaryForeground;
    if (theme.styles.secondaryForeground)
      colors.brandSecondaryRegular = theme.styles.secondaryForeground;
    return { colors };
  }, [theme.styles.primaryForeground, theme.styles.secondaryForeground]);

  return (
    <div id="preact_root">
      <IdsProvider themeOverrides={themeOverrides}>
        <FontLoader fontFamily="Instacart Sans" variants={InstacartSansFontLoaderVariants} />
        <Theme.Provider value={{ ...theme }}>{children}</Theme.Provider>
      </IdsProvider>
    </div>
  );
}
