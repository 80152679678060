import { createContext } from "preact";
import {
  ConfigurationForOrderStatus,
  FontFamily,
  Styles,
  ToggledWidgets,
  Widget,
} from "../types/theme";
import { ErrorResponse } from "../api";

export type ThemeContext = ConfigurationForOrderStatus &
  Readonly<{
    applyTheme: (theme: Styles) => void;
  }>;

export const defaultConfiguration: ConfigurationForOrderStatus = {
  styles: {
    primaryBackground: "#ffffff",
    primaryForeground: "",
    secondaryBackground: "",
    secondaryForeground: "",
    buttonRadius: 0,
  },
  branding: {
    name: "Walmart.ca",
    logoUrl: "",
    retailerUrl: "",
  },
  copy: {
    thankYou: "Thank you!",
    tipPrompt: "Update Tip?",
  },
  toggledWidgets: [
    Widget.delivery_status,
    Widget.delivery_map,
    Widget.delivery_details,
    Widget.item_list,
    Widget.chat,
    Widget.pickup,
  ],
};

export const defaultTheme: ThemeContext = {
  ...defaultConfiguration,
  applyTheme,
};

export const Theme = createContext<ThemeContext>(defaultTheme);

export function shouldHideWidget(widgetName: Widget, toggledWidgets: ToggledWidgets): boolean {
  return !toggledWidgets.includes(widgetName);
}

export function isErrorResponse(
  value: ConfigurationForOrderStatus | ErrorResponse
): value is ErrorResponse {
  const e = value as Partial<ErrorResponse>;
  return !!e.error;
}

export function getTheme(
  data: ConfigurationForOrderStatus | ErrorResponse = defaultConfiguration
): ThemeContext {
  if (isErrorResponse(data)) return defaultTheme;
  const theme: ThemeContext = {
    ...data,
    applyTheme,
  };

  return theme;
}

export function parseCustomFont(fontFamily: FontFamily): string {
  const fontFamilyCss: string[] = [];
  const currentFont: string[] = [];
  fontFamily.forEach((font) => {
    const { name: fontName, fontVariants } = font;
    fontVariants.forEach((fontVariant) => {
      const { weight, weights } = fontVariant;
      currentFont.push(`
        @font-face {
          font-family: "${fontName}";
          font-weight: ${weight};\n`);
      weights.forEach((fontWeight) => {
        const { name: format, url } = fontWeight;
        currentFont.push(`src: url("${url}") format("${format}"); \n`);
      });

      currentFont.push(`}`);
    });
    fontFamilyCss.push(currentFont.join(""));
  });
  return fontFamilyCss.join("");
}

function applyCustomFontFace(customFontFamily: FontFamily | undefined): string {
  let fontName = "";
  if (customFontFamily && Array.isArray(customFontFamily)) {
    const fontFace = customFontFamily ? parseCustomFont(customFontFamily) : "";
    fontName = customFontFamily?.[0]?.name;
    const style = document.createElement("style");
    document.head.append(style);
    style.textContent = fontFace;
  }

  return ["'Instacart Sans'", fontName, "sans-serif"].filter((e) => !!e).join(", ");
}

function applyTheme(theme: Styles): void {
  let fontName = "";
  try {
    fontName = applyCustomFontFace(theme.fontFamily);
  } catch (e) {}

  const cssVariables = [
    `--primary-background-color: ${theme.primaryBackground}`,
    `--primary-foreground-color: ${theme.primaryForeground}`,
    `--primary-foreground-color-30-pct-opacity: ${theme.primaryForeground}4d`,
    `--secondary-background-color: ${theme.secondaryBackground}`,
    `--secondary-foreground-color: ${theme.secondaryForeground}`,
    `--font-family: ${fontName}`,
    `--button-radius: ${theme?.buttonRadius}px`,
    `--header-height: 85px`,
  ];
  const root = document.getElementsByTagName("html")[0];
  root.style.cssText = cssVariables.join(";");
}
